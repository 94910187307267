import API from './config'

export const LossProvisionAPI = {

    async getYearsLossProvision(clientId) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.get(`/loss_provision/getYearsLossProvision?client_id=${clientId}`, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },

    async getYearLossProvision(clientId, year) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.get(`/loss_provision/getYearLossProvision?client_id=${clientId}&year=${year}`, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },

    async saveLossProvisionYear(clientId, year, lossProvisionJson) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.post(`/loss_provision/saveLossProvisionYear`, {
                client_id: clientId,
                year: year,
                loss_provision_json: lossProvisionJson,
            }, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },

}