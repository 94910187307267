import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

//Controllers

import { headerSearch, tabs, unoTheme } from '../../../../../assets/styles/unoTheme';


import { TableBody, TableCell, TableFooter, TableHead, TableRow, Tooltip, withStyles } from "@material-ui/core";
import { isEmpty } from 'lodash';
import moment from 'moment';
import allActions from '../../../../../actions';
import { getVaRs } from '../../../../../API/comdinheiro-api';
import { getFundsInfosByFundIds, getSettledFundsIdsByFundsIdsAndReferenceDate } from '../../../../../API/fund';
import { formatMyFundsToShow } from '../../../../../controllers/FundController';
import { rentsByPeriod } from '../../../../../controllers/ReportController';
import DefaultScrollWithHeaderContent from '../../../../utils/DefaultScrollWithHeaderContent';
import MyTable from '../../../../utils/MyTable';
import { applyMaskAmount, applyMaskAmountFreeDecimals, getDayMonthYearByDate, getFirstBeforeBusinessDay, getFirstDayMonth, getLastDayInMonthByMonthAndYear, getNovember, getYearsWithNovembers, portfolioClosedToDate } from '../../../../utils/utils';


import { LossProvisionAPI } from '../../../../../API/loss_provision';
import UnoButton from '../../../../utils/UnoButton';

const LightTooltip = withStyles((theme) => ({
    arrow: {
        "&:before": {
            border: "1px solid #000000"
        },
        color: theme.palette.common.white
    },
    tooltip: {
        fontSize: '15px',
        backgroundColor: theme.palette.common.white,
        border: "1px solid #000000",
        color: "#000000"
    }
}))(Tooltip);

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    toStickyHeader: {
        display: 'flex',
        height: '100%',
    },
    dirName: {
        color: 'black',
        cursor: 'pointer',
        '&:hover': {
            color: 'blue'
        }
    },
    file: {
        cursor: 'pointer',
        '&:hover': {
            color: 'blue',
            '& svg': {
                color: 'blue'
            }
        }

    },
    tabs: tabs,

    demonstrativeContainer: {
        overflowY: 'scroll',
        width: '100%',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',

        '& .demonstrativeFund': {

            marginBottom: '32px',

            '& .fundName': {
                color: unoTheme.defaultTextColor,
                paddingBottom: '16px',
                textAlign: 'left',
                fontWeight: 'bold',
                fontSize: '18px',

            },

            '& .demonstrativeTopInfos': {
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: '16px',

                '& .demonstrativeTopInfosLeft': {
                    paddingRight: '16px',
                },
                '& .demonstrativeTopInfosRight': {
                    borderLeft: 'solid 1px ' + unoTheme.mainColor,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                }
            }
        }
    },
    tableBlade: {

        tableLayout: 'fixed',


        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                fontSize: '12px',
                padding: '4px 4px',
                fontWeight: "bold",
                color: '#1C2228',
                wordWrap: 'break-word',
            },

            '& .MuiTableCell-root:first-child': {
                paddingLeft: '16px',
                borderRight: 'solid 1.5px #E4E7EB'
            },
            '& > *': {
                borderBottom: 'unset',
            },
        },

        '& .MuiTableBody-root': {

            '& .MuiTableRow-root': {
                borderRadius: '10px',
                '&:nth-of-type(odd)': {
                    backgroundColor: unoTheme.secondaryTuplaTable,
                    //backgroundColor: 'red',
                },

                '&:nth-of-type(even)': {
                    backgroundColor: unoTheme.mainColor,
                    color: 'white',

                    '& .MuiTableCell-root': {
                        color: 'white',
                    },
                    //backgroundColor: 'red',
                },

                '& > *': {
                    borderBottom: 'unset',
                },
            },

            '& .MuiTableCell-root': {
                fontSize: '12px',
                padding: '12px 4px',
                color: '#768191',
            },

            '& .MuiTableCell-root:first-child': {
                borderRight: 'solid 1.5px #E4E7EB',
                paddingLeft: '16px',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
            },

            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
            },
        },


    },

    gridPaperTable: {
        borderRadius: '10px',
    },

    ellipsis: {
        whiteSpace: "nowrap", /* Impede a quebra de linha */
        overflow: "hidden", /* Garante que o texto extra seja ocultado */
        textOverflow: "ellipsis",
    },

    myTableFooter: {
        cursor: "pointer",
        borderBottom: 'unset',
        '& > *': {
            borderBottom: 'unset',
        },

        '& .totalLinePL': {
            '& .MuiTableCell-root': {
                color: 'black',
                borderBottom: 'unset',
                background: unoTheme.mainGrayColor,
            },
        },

        '& .MuiTableCell-root:first-child': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
        },

        '& .MuiTableCell-root:last-child': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
        },

        '& .spanTotal': {
            //color: unoTheme.greenMoney,
            fontSize: '14px',
        }
    },

    flexCenterDiv: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }


});


export default function LossProvisionTab(props) {

    const { selectedYearLossProvision,
        setOptionsYearsLossProvision, setLossProvisionResult } = props;

    const classes = useStyles();
    const dispatch = useDispatch();

    const clientOn = useSelector(state => state.clientOn)
    const [settledFundsIds, setSettledFundsIds] = useState([]);
    const [fundsFormatteds, setFundsFormatteds] = useState([]);

    const [theNovember, setTheNovember] = useState();

    const [enableGenerateLossProvisionYear, setEnableGenerateLossProvisionYear] = useState(false);

    const [totalLoss, setTotalLoss] = useState();

    const [innerLoad, setInnerLoad] = useState({
        emptyMessage: "",
        loadMessage: "Carregando provisão de perdas...", size: "small"
    });

    async function loadFundsYear(client, optionYear) {

        setInnerLoad({
            emptyMessage: "",
            loadMessage: "Gerando provisão " + optionYear + "...", size: "small"
        })
        const lastNovember = getNovember(optionYear);
        console.log("lastNovember ", lastNovember);
        const { month, year } = getDayMonthYearByDate(lastNovember);

        setTheNovember(moment.utc(lastNovember).format("MM/YYYY"));

        const currentPeriod = month + '/' + year;
        const startDate = moment.utc(client.portfolio_init).format('DD/MM/YYYY');
        const endDate = getLastDayInMonthByMonthAndYear(month, year);

        const responseRents = await rentsByPeriod(client.id, startDate, endDate, "", 0);

        if (responseRents.integralFundsBalancesMap) {

            if (Object.keys(responseRents.integralFundsBalancesMap).length) {

                const fundsIds = Object.keys(responseRents.integralFundsBalancesMap);

                const responseSettledFundsIds = await getSettledFundsIdsByFundsIdsAndReferenceDate(fundsIds, getFirstDayMonth(month, year));

                let setteldsIds = [];
                if (responseSettledFundsIds?.success) {

                    setteldsIds = responseSettledFundsIds.body.rows.map(el => el.id + "");
                    setSettledFundsIds(setteldsIds);
                }

                const responseFundsInfos = await getFundsInfosByFundIds(fundsIds);

                if (responseFundsInfos.success) {
                    //transformar em map
                    const mapFundsInfos = {};
                    const justCnpjs = [];
                    responseFundsInfos.body.rows.forEach(el => {
                        mapFundsInfos[el.id] = el;
                        justCnpjs.push(el.cnpj);
                    })


                    const responseVaRs = await getVaRs(getFirstBeforeBusinessDay(endDate), justCnpjs);

                    if (isEmpty(responseRents.integralFundsBalancesMap)) {
                        setInnerLoad({
                            emptyMessage: "Não existem fundos",
                        });
                    }

                    const preFormatted = formatMyFundsToShow(responseRents.integralFundsBalancesMap, currentPeriod, mapFundsInfos);

                    if (responseVaRs?.success) {

                        console.log("responseVaRs: ", responseVaRs.body);
                        const { body } = responseVaRs;
                        let loss = 0;
                        preFormatted.forEach(el => {

                            if (body[el.cnpj] != null) {
                                const factor = body[el.cnpj].var * Math.sqrt(252);
                                el.factor = factor;
                                if (el.balance_now != null) {
                                    el.provision = el.balance_now * factor;
                                    loss += el.provision;
                                }
                                el.var = factor;
                                el.varMode = body[el.cnpj].varMode;
                            }
                        })

                    }

                    const responseSaveLossProvisionYear = await LossProvisionAPI.saveLossProvisionYear(client.id,
                        selectedYearLossProvision,
                        JSON.stringify(preFormatted.filter(el =>
                            !setteldsIds.includes(el.fundId)
                            && el.balance_now > 0
                            && el.var > 0
                        )));


                    loadYearLossProvision(client.id, selectedYearLossProvision);

                } else {
                    setInnerLoad(null);

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        message: "Falha ao trazer fundos do cliente",
                        type: 'error',
                    }))
                }
            } else {
                setInnerLoad({ emptyMessage: "Não existem fundos" })

            }



        } else {

            setInnerLoad(null);

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: "Falha ao trazer fundos do cliente",
                type: 'error',
            }))
        }
    }


    const loadYearLossProvision = async (clientId, year) => {

        const responseYearLoss = await LossProvisionAPI.getYearLossProvision(clientId, year);
        if (responseYearLoss.success) {

            if (responseYearLoss.body?.rows?.length == 0) {
                setEnableGenerateLossProvisionYear(true);
                setInnerLoad(null);
            } else {

                setEnableGenerateLossProvisionYear(false);
                const theLossProvision = responseYearLoss.body.rows[0];
                const formattedFunds = JSON.parse(theLossProvision.loss_provision);
                setTotalLoss(formattedFunds.reduce((sum, item) => sum + (item.provision ? item.provision : 0), 0));
                const result = JSON.parse(theLossProvision.loss_provision);
                console.log("result: ", result);
                setFundsFormatteds(result);
                setInnerLoad(null);

            }

            console.log("responseYearLoss: ", responseYearLoss);

        }
        //loadFundsYear(clientOn.client);
    }

    const loadOptionsYearsToLossProvision = (client) => {

        const portfolioClosedDate = portfolioClosedToDate(client.portfolio_closed);
        const startDate = new Date(2024, 0, 1);

        const yearsWithNovembers = getYearsWithNovembers(startDate, portfolioClosedDate);

        setOptionsYearsLossProvision(yearsWithNovembers);

        if (yearsWithNovembers.length === 0) {
            setInnerLoad({
                emptyMessage: "A carteira deve possuir informação lançada pelo menos a partir de Nov/2024",
            })
        }

    }

    useEffect(() => {

        if (fundsFormatteds?.length) {
            setLossProvisionResult(fundsFormatteds);
        }
    }, [fundsFormatteds])

    useEffect(() => {
        if (clientOn?.client?.id && selectedYearLossProvision) {

            loadYearLossProvision(clientOn.client.id, selectedYearLossProvision);
        }
    }, [clientOn?.client?.id, selectedYearLossProvision])

    useEffect(() => {
        if (clientOn?.client?.id) {

            loadOptionsYearsToLossProvision(clientOn.client);
        }
    }, [clientOn?.client?.id])

    return (
        <div className={classes.root}>

            <DefaultScrollWithHeaderContent innerLoad={innerLoad}>

                {
                    enableGenerateLossProvisionYear && innerLoad == null ?

                        <div className={classes.flexCenterDiv}>

                            <UnoButton
                                onClick={() => loadFundsYear(clientOn.client, selectedYearLossProvision)}
                                style={{ marginRight: '16px' }}
                                size={'small'}
                                disabled={!enableGenerateLossProvisionYear}
                                type='primary'>
                                {"Gerar provisão de perdas " + (selectedYearLossProvision ? ("[" + selectedYearLossProvision + "]") : "")}
                            </UnoButton>

                        </div>

                        :

                        <MyTable stickyHeader
                            size="small">
                            <colgroup>
                                <col width="35%" />
                                <col width="25%" />
                                <col width="15%" />
                                <col width="10%" />
                                <col width="15%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Fundo</TableCell>
                                    <TableCell align="center">CNPJ</TableCell>
                                    <TableCell align="right">Saldo {theNovember}</TableCell>
                                    <TableCell align="center">VaR <span style={{ fontSize: "8px" }}>252,756</span></TableCell>
                                    <TableCell align="right">Provisão</TableCell>
                                </TableRow>
                            </TableHead>

                            {
                                clientOn?.client && fundsFormatteds?.length ?
                                    <TableBody>
                                        {fundsFormatteds.map((row, index) => (

                                            <TableRow
                                                key={'client-fund-' + index}
                                            >
                                                <TableCell align="left">{row.fund_name}</TableCell>
                                                <TableCell align="center">{row.cnpj}</TableCell>
                                                <TableCell align="right" className={classes.green}>{row.balance_now != null ? applyMaskAmount(row.balance_now, true) : "-"}</TableCell>
                                                <TableCell align="center" style={{ color: row.varMode == "36m" ? "blue" : "blueviolet", cursor: row.varMode == "36m" ? "default" : "pointer" }}>
                                                    <LightTooltip
                                                        title={"VaR de " + row.varMode}
                                                        placement="top" arrow>
                                                        <span>{row.var != null ? applyMaskAmountFreeDecimals(row.var, null, 6) : "-"}</span>
                                                    </LightTooltip>
                                                </TableCell>
                                                <TableCell align="right" style={{ color: "orangered" }}>{row.provision != null ? applyMaskAmount(row.provision, true) : "-"}</TableCell>

                                            </TableRow>

                                        ))}
                                    </TableBody>
                                    : null
                            }

                            {
                                clientOn?.client && fundsFormatteds?.length &&
                                <TableFooter className={classes.myTableFooter}>
                                    <TableRow className='totalLinePL' key={'footer-loss-provisional'}>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="right">TOTAL</TableCell>
                                        <TableCell align="right"><span className={'spanTotal'}>{totalLoss != null ?
                                            applyMaskAmount(totalLoss, true) : '-'}</span></TableCell>
                                    </TableRow>
                                </TableFooter>
                            }
                        </MyTable>
                }

            </DefaultScrollWithHeaderContent>

        </div>
    );
}



